import { useEffect, useRef, useCallback } from "react"
import { EventSourcePolyfill } from 'event-source-polyfill';


import { useAuth } from "./useAuth"
import { API_BASE_URL } from "../services/api"

export const useGenerateModel = () => {
  const eventSourceRef = useRef(null)
  const { token } = useAuth();
  const generate = useCallback(({
    projectId,
    iterationId,
    onMessage,
    onClose,
  }) => {
    if(!token || !projectId || !iterationId) {
      return
    }
    eventSourceRef?.current?.close?.()

    eventSourceRef.current = new EventSourcePolyfill(`${API_BASE_URL}/projects/generate-models/${projectId}/${iterationId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    eventSourceRef.current?.addEventListener("message", (msg) => {
      onMessage(msg.data)
    })

    eventSourceRef.current?.addEventListener("close", () => {
      onClose()
      eventSourceRef?.current?.close?.()
    })

    eventSourceRef.current?.addEventListener("error", (err) => {
      console.log(err || 'Unexpected error')
      eventSourceRef?.current?.close?.()
    })


  }, [token])

  useEffect(() => {
    return () => {
      eventSourceRef?.current?.close?.()
    }
  }, [])

  return generate
}