import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from './animationData.json'

export default function LoadingAnimation() {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }


  return (
    <Lottie
      options={defaultOptions}
      height={340}
      width={260}
    />
  )
}