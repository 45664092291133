import { useEffect } from "react";
import { Box, Typography, Paper } from "@mui/material";
import FeedbackPage from "../FeedbackPage/FeedbackPage";
import DesignChoicePage from "../DesignChoicePage/DesignChoicePage";
import { useModelState } from "../../hooks/useModelState";
import { useModelViewState } from "../../hooks/useModelViewState";
import LoadingAnimation from "../LoadingAnimation";

import ProjectStartForm from "../ProjectStartPage/ProjectStartPage";
import Layout from "../Layout";

export default function ProjectPage() {
  const {
    description,
    setDescription,
    pendingModels,
    feedback,
    setFeedback,
    models,
    currentModelIndex,
    loading,
    error,
    projectId,
    modelRef,
    handleNextIteration,
    handleModelSelect,
    setImageWasAnnotated,
    createProject,
    projectStartImage,
    setProjectStartImage,
    resetState,
  } = useModelState();

  const { viewStates, updateViewState, initializeViewStates } =
    useModelViewState();

  useEffect(() => {
    initializeViewStates(models.length);
  }, [models, initializeViewStates, resetState]);

  if (loading) {
    return (
      <Layout resetState={resetState}>
        <Box my={4}>
          <LoadingAnimation />
        </Box>
      </Layout>
    );
  }

  return (
    <Layout resetState={resetState}>
      <Box my={4}>
        {!projectId && (
          <>
            <Typography gutterBottom variant="h4">
              Start a new project
            </Typography>
            <ProjectStartForm
              description={description}
              setDescription={setDescription}
              createProject={createProject}
              loading={loading}
              projectStartImage={projectStartImage}
              setProjectStartImage={setProjectStartImage}
            />
          </>
        )}

        {projectId && currentModelIndex === null && (
          <>
            <Typography variant="h4" gutterBottom>
              Pick design for next iteration
            </Typography>
            <Paper elevation={1} sx={{ p: 1 }}>
              <Typography variant="body2">
                Your description:{" "}
                <Box as="span" sx={{ fontStyle: "italic" }}>
                  {description}
                </Box>
              </Typography>
            </Paper>
            <DesignChoicePage
              models={models}
              pendingModels={pendingModels}
              error={error}
              viewStates={viewStates}
              updateViewState={updateViewState}
              handleModelSelect={handleModelSelect}
              resetState={resetState}
            />
          </>
        )}

        {currentModelIndex !== null && (
          <>
            <Typography variant="h4" gutterBottom>
              Make some adjustments
            </Typography>
            <Paper elevation={1} sx={{ p: 1 }}>
              <Typography variant="body2">
                Previous description:{" "}
                <Box as="span" sx={{ fontStyle: "italic" }}>
                  {description}
                </Box>
              </Typography>
            </Paper>
            <FeedbackPage
              models={models}
              currentModelIndex={currentModelIndex}
              viewStates={viewStates}
              updateViewState={updateViewState}
              modelRef={modelRef}
              feedback={feedback}
              setFeedback={setFeedback}
              handleNextIteration={handleNextIteration}
              setImageWasAnnotated={setImageWasAnnotated}
              loading={loading}
            />
          </>
        )}
      </Box>
    </Layout>
  );
}
