import React from "react";
import { Box, Grid } from "@mui/material";
import ModelViewer from "../ModelViewer/ModelViewer";
import LoadingAnimation from "../LoadingAnimation"

function DesignChoicePage({
  models,
  pendingModels,
  error,
  viewStates,
  updateViewState,
  handleModelSelect,
}) {
  const seenCodes = new Set();
  // Don't show models that have same code as previous models or are errorred
  const modelsToShow = models.filter((model) => {
    if (seenCodes.has(model.code) || model.error || !model.stl_url) {
      return false;
    }
    seenCodes.add(model.code);
    return true;
  });

  const isOdd = (modelsToShow.length % 2) === 1

  return (
    <Box my={4}>
      <Grid container spacing={3} justifyContent="center">
        {modelsToShow.map((model, index) => (
          <Grid item xs={12} md={6} lg={6} key={index} sx={{ minWidth: 400 }}>
            <ModelViewer
              modelNum={models.indexOf(model)} // Original index
              model={model}
              error={error}
              viewStates={viewStates}
              updateViewState={updateViewState}
              handleModelSelect={handleModelSelect}
              dedupedModelId={index} // User-facing ID
            />
          </Grid>
        ))}
        {(isOdd || pendingModels) && (
          <Grid item xs={12} md={6} lg={6} sx={{ minWidth: 400 }}>
            {pendingModels && (
              <Box sx={{
                width: '100%',
                height: '676px',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
                <LoadingAnimation />
              </Box>)}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default DesignChoicePage;
