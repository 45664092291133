import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import CameraController from "./CameraController";
import { DimensionsText, DimensionsOverlay } from "./DimensionsComponents";

import STLModel from "./STLModel";
import Lights from "./Lights";
import * as THREE from "three";

const CanvasContent = React.forwardRef(
  ({ geometry, viewState, onViewStateChange, boundingBox }, ref) => {
    const { gl, scene, camera } = useThree();

    useImperativeHandle(ref, () => ({
      captureImage: () => {
        if (gl && scene && camera) {
          gl.render(scene, camera);
          const dataURL = gl.domElement.toDataURL("image/png");
          return dataURL;
        }
        return null;
      },
    }));

    return (
      <>
        <CameraController
          viewState={viewState}
          onViewStateChange={onViewStateChange}
          boundingBox={boundingBox}
        />
        <Lights />
        <STLModel geometry={geometry} />
        <DimensionsText boundingBox={boundingBox} />
      </>
    );
  }
);

const ModelCanvas = React.forwardRef(
  ({ stlUrl, viewState, onViewStateChange }, ref) => {
    const [geometry, setGeometry] = useState(null);
    const [boundingBox, setBoundingBox] = useState(null);
    const contentRef = useRef(null);

    useEffect(() => {
      if (!stlUrl) {
        console.log("No STL URL provided");
        return;
      }

      setGeometry(null);
      setBoundingBox(null);
      const loader = new STLLoader();
      loader.load(
        stlUrl,
        (loadedGeometry) => {
          setGeometry(loadedGeometry);
          loadedGeometry.computeBoundingBox();
          const bbox = loadedGeometry.boundingBox;
          if (bbox) {
            const center = bbox.getCenter(new THREE.Vector3());
            const size = bbox.getSize(new THREE.Vector3());
            setBoundingBox({ center, size });
          }
        },
        (xhr) => {
          console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        },
        (error) => {
          console.error("Error loading STL:", error);
        }
      );
    }, [stlUrl]);

    useImperativeHandle(ref, () => ({
      captureImage: () => {
        return contentRef.current?.captureImage() || null;
      },
    }));

    return (
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <Canvas style={{borderRadius: '6px'}}>
          <color attach="background" args={["grey"]} />
          <CanvasContent
            ref={contentRef}
            geometry={geometry}
            viewState={viewState}
            onViewStateChange={onViewStateChange}
            boundingBox={boundingBox}
          />
        </Canvas>
        <DimensionsOverlay boundingBox={boundingBox} />
      </div>
    );
  }
);

export default ModelCanvas;
