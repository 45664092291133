import axios from "axios";

export const API_BASE_URL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:8000";

class ApiError extends Error {
  constructor(message, status, endpoint) {
    super(message);
    this.name = "ApiError";
    this.status = status;
    this.endpoint = endpoint;
  }
}

const handleApiRequest = async (endpoint, method, data, token) => {
  try {
    const response = await axios({
      method,
      url: `${API_BASE_URL}/${endpoint}`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    const status = error.response?.status;
    const errorMessage = error.response?.data?.message || error.message;

    console.error(`API Error (${endpoint}):`, {
      status,
      message: errorMessage,
      endpoint,
      method,
    });

    if (status === 401) {
      throw new ApiError(
        "Unauthorized. Please log in again.",
        status,
        endpoint
      );
    } else if (status === 404) {
      throw new ApiError("Resource not found.", status, endpoint);
    } else {
      throw new ApiError(`Request failed: ${errorMessage}`, status, endpoint);
    }
  }
};

export const getCurrentUser = async (token) => {
  return handleApiRequest("auth/user", "GET", null, token);
};

export const createProject = (projectData, token) => {
  return handleApiRequest(
    "projects/create-project",
    "POST",
    projectData,
    token
  );
};

export const generateModels = (projectData, token) => {
  return handleApiRequest(
    "projects/generate-models",
    "POST",
    projectData,
    token
  );
};

export const submitFeedback = (feedbackData, token) => {
  return handleApiRequest(
    "projects/next-iteration",
    "POST",
    feedbackData,
    token
  );
};

export const selectDesign = (designData, token) => {
  return handleApiRequest("projects/select-design", "POST", designData, token);
};
