import React from "react";
import { Text } from "@react-three/drei";

export const DimensionsText = ({ boundingBox }) => {
  if (!boundingBox) return null;

  const { size } = boundingBox;
  const text = `${size.x.toFixed(1)} x ${size.y.toFixed(1)} x ${size.z.toFixed(
    1
  )} (cm)`;

  return (
    <Text
      position={[0.9, -0.9, 0]}
      fontSize={0.05}
      color="white"
      anchorX="right"
      anchorY="bottom"
    >
      {text}
    </Text>
  );
};

export const DimensionsOverlay = ({ boundingBox }) => {
  if (!boundingBox) return null;

  const { size } = boundingBox;
  const text = `Size: ${size.x.toFixed(1)} x ${size.y.toFixed(
    1
  )} x ${size.z.toFixed(1)} (cm)`;

  return (
    <div
      style={{
        position: "absolute",
        bottom: "12px",
        right: "12px",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        color: "white",
        padding: "4px 12px",
        borderRadius: "8px",
        fontSize: "12px",
        fontFamily: "Roboto",
      }}
    >
      {text}
    </div>
  );
};
