import React, { useState } from "react";
import {
  Button,
  Box,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Model from "./Model";
import DownloadSTLButton from "./DownloadSTLButton";

const ModelViewer = ({
  modelNum,
  model,
  loading,
  error,
  viewStates,
  updateViewState,
  handleModelSelect,
  dedupedModelId,
}) => {
  const [codeDialogOpen, setCodeDialogOpen] = useState(false);

  const handleShowCode = () => {
    setCodeDialogOpen(true);
  };

  const handleCloseCode = () => {
    setCodeDialogOpen(false);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!model) return <Typography>Waiting for model...</Typography>;

  return (
    <Box width="90%">
      <Typography sx={{ fontWeight: 500, color: "#A0a0a0" }} variant="body1">
        #{dedupedModelId + 1}
      </Typography>

      <Model
        stlUrl={model.stl_url}
        code={model.code}
        viewState={viewStates[modelNum]}
        onViewStateChange={(newState) => updateViewState(modelNum, newState)}
      />
      <Box sx={{ mt: 2, display: "flex", gap: 1, justifyContent: "end" }}>
        <Typography variant="body1">
          {model.LLM}
          {model.response_model}
          {model.temperature}
        </Typography>
        <DownloadSTLButton stlUrl={model.stl_url} />
        <Button size="small" onClick={handleShowCode}>
          Get Code
        </Button>
        <Button
          onClick={() => handleModelSelect(modelNum)}
          variant="contained"
          color="primary"
          size="small"
        >
          Pick
        </Button>
      </Box>
      <Dialog open={codeDialogOpen} onClose={handleCloseCode} maxWidth="md">
        <DialogTitle>Code</DialogTitle>
        <DialogContent>
          <pre
            style={{
              whiteSpace: "pre-wrap",
              fontFamily: "monospace",
              overflowX: "auto",
            }}
          >
            {model.code}
          </pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCode} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ModelViewer;
