import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const ModelHelperDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Moving Around In The Object Viewer</DialogTitle>
      <DialogContent>
        <table>
          <tbody>
            <tr>
              <td />
              <td
                style={{
                  paddingRight: "12px",
                  paddingBottom: "8px",
                  fontWeight: "bold",
                }}
              >
                Trackpad
              </td>
              <td style={{ paddingRight: "16px", fontWeight: "bold" }}>
                Mouse
              </td>
            </tr>
            <tr>
              <td style={{ paddingRight: "24px", fontWeight: "bold" }}>Zoom</td>
              <td style={{ paddingRight: "16px" }}>Two-finger scroll</td>
              <td>Scroll Mouse wheel</td>
            </tr>
            <tr>
              <td style={{ paddingRight: "24px", fontWeight: "bold" }}>
                Rotate
              </td>
              <td style={{ paddingRight: "16px" }}>One-finger drag</td>
              <td>Right click + drag</td>
            </tr>
            <tr>
              <td style={{ paddingRight: "24px", fontWeight: "bold" }}>Pan</td>
              <td style={{ paddingRight: "16px" }}>
                Shift + one-finger drag OR two-finger drag
              </td>
              <td>Left click + drag</td>
            </tr>
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModelHelperDialog;
