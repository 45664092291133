import React, { useState, useRef, useMemo } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import UAParser from "ua-parser-js"

const options = ['Upload', 'Take a picture'];

const UploadButton = ({ onUpload, onScreenshot, sx }) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMemo(() => {
    const uaParser = new UAParser()
    const os = uaParser.getOS().name
    return os.includes("Android") || os.includes("iOS")
  }, [])
  const anchorRef = useRef(null);

  const handleMenuItemClick = (option) => {
    if(option === 'Upload') {
      onUpload()
    } else {
      onScreenshot()
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleMainClick = () => {
    if(!isMobile) {
      handleToggle()
    } else {
      onUpload()
    }
  }

  return (
    <>
      <ButtonGroup
        sx={sx}
        variant="outlined"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <Button onClick={handleMainClick}>{"Upload Image (OPTIONAL)"}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select upload image option"
          aria-haspopup="menu"
          onClick={handleMainClick}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 2,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={6}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      sx={{width: '266px'}}
                      onClick={() => handleMenuItemClick(option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default UploadButton