import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 6,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 6,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          fontSize: '1rem',
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          borderRadius: 6,
          textTransform: 'none',
        },
      },
    },

    MuiTypography: {
      variants: [
        {
          props: { variant: 'h1' },
          style: {
            /* your style here: */
            fontFamily: 'Noto Serif',
            fontWeight: 500,
          },
        },
        {
          props: { variant: 'h2' },
          style: {
            /* your style here: */
            fontFamily: 'Noto Serif',
            fontWeight: 500,
          },
        },
        {
          props: { variant: 'h3' },
          style: {
            /* your style here: */
            fontFamily: 'Noto Serif',
            fontWeight: 500,
          },
        },
        {
          props: { variant: 'h4' },
          style: {
            /* your style here: */
            fontFamily: 'Noto Serif',
            fontWeight: 500,
          },
        },
        {
          props: { variant: 'h5' },
          style: {
            /* your style here: */
            fontFamily: 'Noto Serif',
            fontWeight: 500,
          },
        },
        {
          props: { variant: 'h6' },
          style: {
            /* your style here: */
            fontFamily: 'Noto Serif',
            fontWeight: 500,
          },
        },
      ],
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#2563EB',
    },
    secondary: {
      main: '#1E3A8A',
    },
  },
  typography: {
    fontFamily: 'Roboto',
  },
});

export default theme;
