import React, {useRef, useCallback} from "react"
import Webcam from "react-webcam"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material"

const Screenshot = ({
  onCapture,
  open,
  onClose,
}) => {
  const webcamRef = useRef(null);
  const capture = useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      onCapture(imageSrc)
    },
    [webcamRef, onCapture]
  );
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Give your browser permission to take a picture
      </DialogTitle>
      <DialogContent>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/png"
          width="100%"
          videoConstraints={{
            facingMode: "environment"
          }}
          screenshotQuality={0.7}

        />
        <Button sx={{mt: 2}} onClick={capture} variant="contained">Capture photo</Button>
      </DialogContent>
    </Dialog>
  );
};

export default Screenshot