import { useState, useCallback } from "react";

export function useModelViewState() {
  const [viewStates, setViewStates] = useState([]);

  const updateViewState = useCallback((modelIndex, newState) => {
    setViewStates((prevStates) => {
      const newViewStates = [...prevStates];
      newViewStates[modelIndex] = newState;
      return newViewStates;
    });
  }, []);

  const initializeViewStates = useCallback((modelCount) => {
    setViewStates(
      Array(modelCount).fill({ position: [0, 0, 200], rotation: [0, 0, 0] })
    );
  }, []);

  return { viewStates, updateViewState, initializeViewStates };
}
