import React from "react";
import { Button } from "@mui/material";

const DownloadSTLButton = ({ stlUrl }) => {
  const handleDownload = async (event) => {
    event.preventDefault();
    if (stlUrl) {
      try {
        const response = await fetch(stlUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `model.stl`;
        link.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Download failed:", error);
      }
    }
  };

  return (
    <Button
      color="primary"
      href={stlUrl}
      download
      disabled={!stlUrl}
      onClick={handleDownload}
      size="small"
    >
      Get STL
    </Button>
  );
};

export default DownloadSTLButton;
