import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Typography, Box, IconButton } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ModelCanvas from "./ModelCanvas";
import ModelHelperDialog from "./ModelHelperDialog";

const Model = forwardRef(
  (
    { stlUrl, code, error, viewState, onViewStateChange, height = "600px" },
    ref
  ) => {
    const containerRef = useRef(null);
    const canvasRef = useRef(null);
    const [key, setKey] = useState(Date.now());
    const [helpDialogOpen, setHelpDialogOpen] = useState(false);

    useEffect(() => {
      setKey(Date.now());
    }, [stlUrl, code]);

    useImperativeHandle(ref, () => ({
      captureImage: () => {
        if (canvasRef.current && canvasRef.current.captureImage) {
          const image = canvasRef.current.captureImage();
          return image;
        }
        return null;
      },
    }));

    const handleHelpOpen = () => {
      setHelpDialogOpen(true);
    };

    const handleHelpClose = () => {
      setHelpDialogOpen(false);
    };

    if (error) {
      return <Typography color="error">{error}</Typography>;
    }

    return (
      <Box
        height={height}
        position="relative"
        ref={containerRef}
      >
        <IconButton
          onClick={handleHelpOpen}
          sx={{ position: "absolute", top: 8, right: 8, zIndex: 1 }}
        >
          <HelpOutlineIcon />
        </IconButton>

        <ModelCanvas
          key={key}
          ref={canvasRef}
          stlUrl={stlUrl}
          viewState={viewState}
          onViewStateChange={onViewStateChange}
        />
        <ModelHelperDialog open={helpDialogOpen} onClose={handleHelpClose} />
      </Box>
    );
  }
);

export default Model;
