import React, { useState, useRef } from "react";
import { TextField, Button, Typography, Paper } from "@mui/material";
import UploadButton from "../UploadButton";
import Screenshot from "../Screenshot"
import Snackbar from "../Snackbar";

function ProjectStartPage({
  description,
  setDescription,
  createProject,
  projectStartImage,
  setProjectStartImage,
}) {
  const [open, setOpen] = useState(false)
  const [screenshotOpen, setScreenshotOpen] = useState(false)
  const uploadInputRef = useRef()

  const handleUploadProject = () => {
    if(projectStartImage || description?.length > 2) {
      createProject()
      return
    }
    setOpen(true)
  }

  const handleUpload = () => {
    uploadInputRef?.current?.click()
  }


  const convertToPng = (file) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        const maxDim = Math.max(width, height);
        if (maxDim > 1024) {
          height = Math.round(height * (1024 / maxDim));
          width = Math.round(width * (1024 / maxDim));
        }
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          resolve(blob);
        }, "image/png");
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      const pngBlob = await convertToPng(file);
      const reader = new FileReader();
      reader.onload = (e) => setProjectStartImage(e.target.result);
      reader.readAsDataURL(pngBlob);
    }
  };

  return (
    <>
      <Screenshot
        open={screenshotOpen}
        onClose={() => { setScreenshotOpen(false) }}
        onCapture={(img) => {
          setProjectStartImage(img)
          setScreenshotOpen(false)
        }}
      />
      <Snackbar
        open={open}
        severity="error"
        onClose={() => setOpen(false)}
        message="You must either include an image or a description to Start a project"
      />
      {projectStartImage ? (
        <Paper
          mt={2}
          sx={{
            mt: 2,
            mb: 2,
            p: 2,
          }}
          elevation={2}
        >
          <Typography variant="h6">Reference Image </Typography>
          <img
            src={projectStartImage}
            alt="Uploaded"
            style={{ maxWidth: "100%", maxHeight: "400px" }}
          />
        </Paper>
      ) : (
        <>
          <input
            accept="image/jpeg,image/png"
            style={{ display: "none" }}
            id="image-upload"
            type="file"
            ref={uploadInputRef}
            onChange={handleImageUpload}
          />
            <UploadButton
              sx={{mb: 2}}
              onUpload={handleUpload}
              onScreenshot={() => { setScreenshotOpen(true) }}
            />
        </>
      )}
      <TextField
        fullWidth
        label="Object Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        margin="normal"
        autoComplete="off"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUploadProject}
      >
        Generate 3D Models
      </Button>
    </>
  );
}

export default ProjectStartPage;
