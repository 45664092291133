import { useState, useRef, useCallback } from "react";
import * as api from "../services/api";
import { useAuth } from "./useAuth";
import { useGenerateModel } from "./useGenerateModel";

export const useModelState = () => {
  const { user, token } = useAuth();

  const { current: initialState } = useRef({
    description: "",
    feedback: "",
    models: [],
    currentModelIndex: null,
    loading: false,
    pendingModels: false,
    error: null,
    projectId: null,
    iterationId: null,
    feedbackImage: null,
    imageWasAnnotated: false,
    projectStartImage: null,
  });

  const [state, setState] = useState(initialState);
  const modelRef = useRef(null);
  const generate = useGenerateModel();

  const resetState = useCallback(() => {
    setState(initialState);
    modelRef.current = null;
  }, [setState, initialState]);

  const createProject = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
      error: null,
    }));

    try {
      const projectData = {
        description: state.description,
        project_start_image: state.projectStartImage,
      };

      if (!token) {
        console.error("Token is missing in createProject");
        throw new Error("Authentication token is missing");
      }

      const data = await api.createProject(projectData, token);

      setState((prev) => ({
        ...prev,
        projectId: data.project_id,
        iterationId: data.iteration_id,
        loading: false,
      }));

      generateModels(data.project_id, data.iteration_id);
    } catch (error) {
      console.error("Create project error:", error);
      let errorMessage = "Failed to create project";
      if (error.response && error.response.data && error.response.data.detail) {
        errorMessage += `: ${error.response.data.detail}`;
      } else if (error.message) {
        errorMessage += `: ${error.message}`;
      }
      setState((prev) => ({
        ...prev,
        error: errorMessage,
        loading: false,
      }));
    }
  };

  const generateModels = async (pId, iId) => {
    setState((prev) => ({
      ...prev,
      loading: true,
      error: null,
    }));
    try {
      generate({
        projectId: pId,
        iterationId: iId,
        onMessage: (stringifiedData) => {
          const data = JSON.parse(stringifiedData || "{}");
          setState((prev) => ({
            ...prev,
            models: [...prev.models, data],
            loading: false,
            pendingModels: true,
          }));
        },
        onClose: () => {
          setState((prev) => ({
            ...prev,
            loading: false,
            pendingModels: false,
          }));
        },
      });
    } catch (error) {
      setState((prev) => ({
        ...prev,
        error: `Failed to generate models: ${error.message}`,
        loading: false,
      }));
    }
  };

  const handleNextIteration = async (imageData) => {
    if (
      state.currentModelIndex === null ||
      !state.projectId ||
      !state.iterationId ||
      !state.feedback
    ) {
      console.error("Missing data for feedback submission");
      return;
    }

    setState((prev) => ({
      ...prev,
      loading: true,
      error: null,
    }));

    try {
      const feedbackData = {
        user_id: user?.id,
        project_id: state.projectId,
        iteration_id: state.iterationId,
        feedback: state.feedback,
        image: imageData,
        image_was_annotated: state.imageWasAnnotated,
      };

      const r = await api.submitFeedback(feedbackData, token);

      setState((prev) => ({
        ...prev,
        feedback: "",
        feedbackImage: null,
        iterationId: r.iteration_id,
        currentModelIndex: null,
        models: [],
      }));

      generateModels(state.projectId, r.iteration_id);
    } catch (error) {
      setState((prev) => ({
        ...prev,
        error: `Failed to get next iteration: ${error.message}`,
        loading: false,
      }));
    }
  };

  const handleModelSelect = async (i) => {
    setState((prev) => ({ ...prev, currentModelIndex: i }));
    const data = {
      user_id: user?.id,
      project_id: state.projectId,
      iteration_id: state.iterationId,
      chosen_code_index: i,
    };

    try {
      await api.selectDesign(data, token);
    } catch (error) {
      console.error(`Error selecting LLM:`, error);
    }
  };

  return {
    ...state,
    setDescription: (description) =>
      setState((prev) => ({ ...prev, description })),
    setFeedback: (feedback) => setState((prev) => ({ ...prev, feedback })),
    setFeedbackImage: (feedbackImage) =>
      setState((prev) => ({ ...prev, feedbackImage })),
    setImageWasAnnotated: (imageWasAnnotated) =>
      setState((prev) => ({ ...prev, imageWasAnnotated })),
    setProjectStartImage: (projectStartImage) =>
      setState((prev) => ({ ...prev, projectStartImage })),
    modelRef,
    createProject,
    generateModels,
    handleNextIteration,
    handleModelSelect,
    resetState,
  };
};
