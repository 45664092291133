import React, { useRef } from "react";
import * as THREE from "three";

function STLModel({ geometry }) {
  const meshRef = useRef();

  if (!geometry) return null;
  return (
    <mesh ref={meshRef} geometry={geometry}>
      <meshStandardMaterial
        color="orange"
        roughness={0.7}
        metalness={0.4}
        side={THREE.DoubleSide}
        customProgramCacheKey={() => "high-precision-depth"}
      />
    </mesh>
  );
}

export default STLModel;
