import { Container } from "@mui/material"
import Navbar from "../Navbar"

export default function Layout({ children, resetState }) {
  return (
    <>
      <Navbar resetState={resetState} />
      <Container maxWidth="lg">
        {children}
      </Container>
    </>
  )
}