import React, { useState } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import Model from "../ModelViewer/Model";
import ImageAnnotator from "./ImageAnnotator";
import Snackbar from "../Snackbar";

const FeedbackPage = ({
  models,
  currentModelIndex,
  viewStates,
  updateViewState,
  modelRef,
  feedback,
  setFeedback,
  handleNextIteration,
  setImageWasAnnotated,
  loading,
}) => {
  const [capturedImage, setCapturedImage] = useState(null);
  const [annotatedImage, setAnnotatedImage] = useState(null);
  const [isAnnotating, setIsAnnotating] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleSubmitFeedback = () => {
    if (!feedback) {
      setShowSnackbar(true)
      return;
    }

    const imageToSend =
      annotatedImage ||
      (modelRef.current ? modelRef.current.captureImage() : null);
    handleNextIteration(imageToSend);
    setCapturedImage(null);
    setAnnotatedImage(null);
    setFeedback("");
    setIsAnnotating(false);
    setImageWasAnnotated(false);
  };

  const handleCaptureView = () => {
    const image = modelRef.current.captureImage();
    setCapturedImage(image);
    setAnnotatedImage(null);
    setIsAnnotating(true);
    setImageWasAnnotated(true);
  };

  return (
    <Box my={4}>
      <Typography sx={{fontSize: '1.125rem'}} variant="body1" gutterBottom>
        {isAnnotating ? "Annotate your image" : "Selected model image"}
      </Typography>
      {isAnnotating ? (
        <ImageAnnotator
          imageData={capturedImage}
          onAnnotationChange={setAnnotatedImage}
        />
      ) : (
        <Model
          ref={modelRef}
          stlUrl={models[currentModelIndex].stl_url}
          code={models[currentModelIndex].code}
          viewState={viewStates[currentModelIndex]}
          onViewStateChange={(newState) =>
            updateViewState(currentModelIndex, newState)
          }
        />
      )}
      {!isAnnotating && (
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCaptureView}
          style={{ marginTop: "12px" }}
        >
          Annotate Image
        </Button>
      )}

      <Box my={4}>
        <TextField
          fullWidth
          label="Provide feedback"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          margin="normal"
          required
          autoComplete="off"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmitFeedback}
          disabled={loading}
          sx={{ mr: 2 }}
        >
          Update With Feedback
        </Button>
      </Box>
      <Snackbar
          open={showSnackbar}
          onClose={handleCloseSnackbar}
          severity="warning"
          message="Please provide feedback before submitting."
      />
    </Box>
  );
};

export default FeedbackPage;
