import { Snackbar as Snack, Alert } from "@mui/material";

export default function Snackbar({
  message,
  open,
  onClose,
  severity,
}) {
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  };

  return (
    <Snack
    open={open}
    autoHideDuration={6000}
    onClose={handleCloseSnackbar}
    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity={severity || "success"}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snack>
  )
}