import React from "react";

function Lights() {
  return (
    <>
      <ambientLight intensity={0.5} />
      <directionalLight position={[1, 5, -5]} intensity={1.2} castShadow />
      <directionalLight position={[-5, 1, 5]} intensity={1.2} castShadow />
      <directionalLight position={[-5, -5, -5]} intensity={1} castShadow />
      <directionalLight position={[5, 5, 5]} intensity={1} castShadow />
      <pointLight position={[0, 10, 0]} intensity={10} castShadow />
      <pointLight position={[0, -10, 0]} intensity={10} castShadow />
      <pointLight position={[10, 0, 0]} intensity={10} castShadow />
      <pointLight position={[-10, 0, 0]} intensity={10} castShadow />
    </>
  );
}

export default Lights;
