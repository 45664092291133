import React, { useRef, useEffect, useState } from "react";
import { useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Vector3 } from "three";

function CameraController({ viewState, onViewStateChange, boundingBox }) {
  const { camera, gl } = useThree();
  const controlsRef = useRef();
  const [initializedForCurrentModel, setInitializedForCurrentModel] =
    useState(false);

  useEffect(() => {
    setInitializedForCurrentModel(false);
  }, [boundingBox]);

  useEffect(() => {
    if (boundingBox && camera && !initializedForCurrentModel) {
      const { center, size } = boundingBox;
      const maxDimension = Math.max(size.x, size.y, size.z);
      const fov = camera.fov * (Math.PI / 180);
      const cameraZ = Math.abs(maxDimension / 2 / Math.tan(fov / 2)) * 2;
      const cameraPosition = new Vector3(
        center.x,
        center.y,
        center.z + cameraZ
      );

      camera.position.copy(cameraPosition);
      camera.lookAt(center);

      if (controlsRef.current) {
        controlsRef.current.target.copy(center);
        controlsRef.current.update();
      }

      onViewStateChange({
        position: camera.position.toArray(),
        rotation: camera.rotation.toArray(),
      });

      setInitializedForCurrentModel(true);
    }
  }, [boundingBox, camera, onViewStateChange, initializedForCurrentModel]);

  useEffect(() => {
    if (controlsRef.current && !boundingBox) {
      camera.position.set(...viewState.position);
      camera.rotation.set(...viewState.rotation);
      controlsRef.current.update();
    }
  }, [camera, viewState, boundingBox]);

  return (
    <OrbitControls
      ref={controlsRef}
      args={[camera, gl.domElement]}
      onChange={() => {
        if (controlsRef.current && boundingBox) {
          onViewStateChange({
            position: camera.position.toArray(),
            rotation: camera.rotation.toArray(),
          });
        }
      }}
    />
  );
}

export default CameraController;
