import React, { useRef, useEffect, useState, useCallback } from "react";
import { Box, Button } from "@mui/material";

const ImageAnnotator = ({ imageData, onAnnotationChange }) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const drawImage = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
    };
    img.src = imageData;
  }, [imageData]);

  useEffect(() => {
    drawImage();
  }, [drawImage]);

  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    const ctx = canvasRef.current.getContext("2d");
    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const draw = ({ nativeEvent }) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = nativeEvent;
    const ctx = canvasRef.current.getContext("2d");
    ctx.strokeStyle = "red";
    ctx.lineWidth = 2;
    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
    updateAnnotatedImage();
  };

  const stopDrawing = () => {
    setIsDrawing(false);
    updateAnnotatedImage();
  };

  const updateAnnotatedImage = () => {
    const annotatedImageData = canvasRef.current.toDataURL("image/png");
    onAnnotationChange(annotatedImageData);
  };

  const clearAnnotation = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawImage();
    updateAnnotatedImage();
  };

  return (
    <Box>
      <canvas
        ref={canvasRef}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseOut={stopDrawing}
        className="annotation-canvas"
      />
      <Button
        variant="outlined"
        color="secondary"
        onClick={clearAnnotation}
        style={{ marginTop: "8px" }}
      >
        Clear Annotation
      </Button>
    </Box>
  );
};

export default ImageAnnotator;
